import { select } from "@utils/function";
import HamburgerMenu from "@components/navbar/hamburgerMenu";

// --------------------------------------------------
// TrackJS error...
import { TrackJS } from "trackjs";

const LOCAL_DOMAINS = ["localhost", "127.0.0.1"];
if (!LOCAL_DOMAINS.includes(window.location.hostname)) {
  TrackJS.install({
    token: "b7b9ae82a8a44a25956a056efdc54e9a",
    version: HQ.Properties.appId,
  });
}

// replace bootstrap hamburger menu
HamburgerMenu();

const customInfo = select(".custom-info");

if (customInfo) {
  document.body.setAttribute("data-info", true);
}

document.addEventListener("DOMContentLoaded", function () {
  const scrollToTopBtn = select(".scroll-to-top");
  const rootElement = document.documentElement;

  function HandleScroll() {
    const scrollTotal = rootElement.scrollHeight - rootElement.clientHeight;
    if (rootElement.scrollTop / scrollTotal > 0.2) {
      scrollToTopBtn?.classList.add("show-scroll-to-top");
    } else {
      scrollToTopBtn?.classList.remove("show-scroll-to-top");
    }
  }

  function ScrollToTop() {
    // Scroll to top logic
    rootElement.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  if (HQ.Properties.typeSite !== "location") {
    scrollToTopBtn?.addEventListener("click", ScrollToTop);
    document.addEventListener("scroll", HandleScroll);
  }

  window.addEventListener("scroll", () => {
    const scrollTotal = rootElement.scrollHeight - rootElement.clientHeight;
    const header = select("header");
    if (rootElement.scrollTop / scrollTotal > 0.1) {
      header.setAttribute("style", "top: -50px");
    }
    if (rootElement.scrollTop / scrollTotal > 0.2) {
      header.classList.add("fixed-top");
      header.removeAttribute("style");
    } else {
      header.classList.remove("fixed-top");
      header.removeAttribute("style");
    }
  });
});

// ---------------------------------
// schedule

const siteSchedule = select(".site-schedule");
if (siteSchedule) {
  const scheduleDate = select(".schedule-date");
  const optionsForDay = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  const newDate = new Date().toLocaleDateString(
    HQ.Properties.lang,
    optionsForDay
  );

  scheduleDate.textContent = newDate;
}
